import React from "react"
import * as Styles from "./index.module.scss"

import LogoSrc from "./logo.svg"


const Logo = ({ className, ...props }) =>
	<img
		src={ LogoSrc }
		alt=""
		className={ [
			Styles.logo,
			className ? className : undefined
		].join(" ") }
		{ ...props }
	/>;


export default Logo;