import React, { Component } from "react"
import { Columns, Content, Heading, Table } from "react-bulma-components"
import FadeIn from "react-fade-in"
import Logo from "../components/logo"
import Page from "../components/page"

// import Content from "react-bulma-components/lib/components/content/content";
// import Columns from "react-bulma-components/lib/components/columns/columns";
// import Column from "react-bulma-components/lib/components/columns/components/column";
// import Heading from "react-bulma-components/lib/components/heading/heading";
// import Table from "react-bulma-components/lib/components/table/table";
import * as Styles from "./index.module.scss"


export default class Home extends Component {
	static propTypes = {};
	
	static defaultProps = {};
	
	render () {
		return (
			<Page>
				<Columns
					className={ Styles.columns }
					centered
					multiline>
					<Columns.Column
						mobile={ { size: 12 }}
						tablet={ { size: "two-thirds" }}
						desktop={ { size: "full" } }>

						<FadeIn>
							<Logo />

							<Columns centered>
								<Columns.Column
									narrow
									className={ Styles.header }
									tablet={ { size: 12 } }>
									<Heading
										size={ 5 }
										renderAs={ "h6" }
										weight={ "bold" }
										subtitle
										textColor={ "primary" }
										className={ Styles.subline }>
										Agrargenossenschaft
									</Heading>

									<Heading
										renderAs={ "h1" }
										size={ 3 }
										textColor={ "text" }>
										Milchquelle Stüdenitz eG
									</Heading>

									<Content size={ "medium" }>
										Am Berg 1 <br/>
										16845 Stüdenitz-Schönermark <br/>
										Deutschland
									</Content>

									<Table
										className={ Styles.table }
										striped={ false }>

										<tbody>
											<tr>
												<td>Fon</td>
												<td><a href="tel:+493397240205">03 39 72 / 4 02 05</a></td>
											</tr>

											<tr>
												<td>Fax</td>
												<td>03 39 72 / 4 19 41</td>
											</tr>

											<tr>
												<td>E-Mail</td>
												<td><a href="mailto:info@milchquelle.com">info@milchquelle.com</a></td>
											</tr>
										</tbody>
									</Table>
								</Columns.Column>
							</Columns>
						</FadeIn>
					</Columns.Column>
				</Columns>
			</Page>
		);
	}
}
